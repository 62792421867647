import React, { useState } from 'react';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { eFaxStatus } from 'src/utils';

export const FilterStatus = ({ onSelectedStatusChange }) => {
  const [selectedStatus, setSelectedStatus] = useState(``);

  const handleStatusChange = (event) => {
    const newSelectedStatus = event.target.value;
    setSelectedStatus(newSelectedStatus);
    onSelectedStatusChange(newSelectedStatus);
  };

  return (
    <MuiSelect
      controlProps={{ size: `small`, fullWidth: true, id: `eFaxStatus` }}
      label="Patient Status Search"
      isSimple={false}
      options={[...eFaxStatus]}
      optionKey={`label`}
      optionValue={`value`}
      selectProps={{
        label: `Patient Status Search`,
        id: `eFaxStatus`,
        name: `selectedStatus`,
        onChange: handleStatusChange,
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '420px',
              width: '280px',
            },
          },
        },
        value: selectedStatus,
      }}
    />
  );
};