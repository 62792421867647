// Resmed
export const RESMED_NASALPILLOWS = [
  { value: `Resmed Swift FX`, label: `Resmed Swift FX` },
  { value: `Resmed Swift FX Bella`, label: `Resmed Swift FX Bella` },
  { value: `Resmed Airfit P10`, label: `Resmed Airfit P10` },
  { value: `Resmed Airfit P30i`, label: `Resmed Airfit P30i` },
  { value: `Resmed AirTouch N30i`, label: `Resmed AirTouch N30i` },
];
export const RESMED_NASAL = [
  { value: `Resmed Swift FX Nano`, label: `Resmed Swift FX Nano` },
  { value: `Resmed Mirage FX`, label: `Resmed Mirage FX` },
  { value: `Resmed Airfit N10`, label: `Resmed Airfit N10` },
  { value: `Resmed Airfit N20`, label: `Resmed Airfit N20` },
  { value: `Resmed Airfit N30`, label: `Resmed Airfit N30` },
  { value: `Resmed Airfit N30i`, label: `Resmed Airfit N30i` },
  { value: `Resmed Airtouch N20`, label: `Resmed Airtouch N20` },
  { value: `Resmed Pixi`, label: `Resmed Pixi` },
];

export const RESMED_FULLFACE = [
  { value: `Resmed Mirage Quattro`, label: `Resmed Mirage Quattro` },
  { value: `Resmed Quattro FX`, label: `Resmed Quattro FX` },
  { value: `Resmed Quattro Air`, label: `Resmed Quattro Air` },
  { value: `Resmed Airfit F10`, label: `Resmed Airfit F10` },
  { value: `Resmed Airfit F20`, label: `Resmed Airfit F20` },
  { value: `Resmed Airfit F30`, label: `Resmed Airfit F30` },
  { value: `Resmed Airfit F30i`, label: `Resmed Airfit F30i` },
  { value: `Resmed Airtouch F20`, label: `Resmed Airtouch F20` },
];
// Respironics
export const RESPIRONICS_NASALPILLOWS = [
  { value: `Respironics Nuance`, label: `Respironics Nuance` },
  { value: `Respironics Nuance ProGel`, label: `Respironics Nuance ProGel` },
  {
    value: `Respironics Dreamwear ProGel nasal pillows`,
    label: `Respironics Dreamwear ProGel nasal pillows`,
  },
  {
    value: `Respironics Dreamwear Silicone nasal pillows `,
    label: `Respironics Dreamwear Silicone nasal pillows `,
  },
];
export const RESPIRONICS_NASAL = [
  {
    value: `Respironics ComfortGel Nasal`,
    label: `Respironics ComfortGel Nasal`,
  },
  { value: `Respironics Wisp`, label: `Respironics Wisp` },
  { value: `Respironics Pico`, label: `Respironics Pico` },
  { value: `Respironics Dreamwear`, label: `Respironics Dreamwear` },
  { value: `Respironics DreamWisp`, label: `Respironics DreamWisp` },
];
export const RESPIRONICS_FULLFACE = [
  {
    value: `Respironics ComfortGel Full-Face`,
    label: `Respironics ComfortGel Full-Face`,
  },
  { value: `Respironics Amara`, label: `Respironics Amara` },
  { value: `Respironics AmaraView`, label: `Respironics AmaraView` },
  {
    value: `Respironics Dreamwear Full-Face`,
    label: `Respironics Dreamwear Full-Face`,
  },
];
export const RESPIRONICS_TOTALFACE = [{ value: `Respironics FitLife`, label: `Respironics FitLife` }];
// Fisher & Paykel
export const FISHER_NASALPILLOWS = [
  { value: `F&P Pilairo`, label: `F&P Pilairo` },
  { value: `F&P Pilairo Q`, label: `F&P Pilairo Q` },
  { value: `F&P Brevida`, label: `F&P Brevida` },
  { value: `F&P Solo Nasal Pillow`, label: `F&P Solo Nasal Pillow` },
  { value: `F&P Nova Micro`, label: `F&P Nova Micro` },
];
export const FISHER_NASAL = [
  { value: `F&P Eson`, label: `F&P Eson` },
  { value: `F&P Eson 2`, label: `F&P Eson 2` },
  { value: `F&P Evora`, label: `F&P Evora` },
  { value: `F&P Solo`, label: `F&P Solo` },
];
export const FISHER_FULLFACE = [
  { value: `F&P Simplus`, label: `F&P Simplus` },
  { value: `F&P Vitera`, label: `F&P Vitera` },
];
export const resmedGroupedOptions = [
  {
    label: `NASAL PILLOWS`,
    options: RESMED_NASALPILLOWS,
  },
  {
    label: `NASAL`,
    options: RESMED_NASAL,
  },
  {
    label: `FULL FACE`,
    options: RESMED_FULLFACE,
  },
];
export const respironicsGroupedOptions = [
  {
    label: `NASAL PILLOWS`,
    options: RESPIRONICS_NASALPILLOWS,
  },
  {
    label: `NASAL`,
    options: RESPIRONICS_NASAL,
  },
  {
    label: `FULL FACE`,
    options: RESPIRONICS_FULLFACE,
  },
  {
    label: `TOTAL FACE`,
    options: RESPIRONICS_TOTALFACE,
  },
];
export const fisherAndPaykelGroupedOptions = [
  {
    label: `NASAL PILLOWS`,
    options: FISHER_NASALPILLOWS,
  },
  {
    label: `NASAL`,
    options: FISHER_NASAL,
  },
  {
    label: `FULL FACE`,
    options: FISHER_FULLFACE,
  },
];
