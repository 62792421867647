// Import the necessary dependencies
import React, { useEffect, useState } from 'react';
import css from 'src/pages/common/components/listview/ListView.module.css';
import { IconButton } from '@mui/material';
import { EXTERNAL_DEVICE_CREDENTIALS_LIST, getRequest } from 'src/crud/crud';
import { SystemAdminViewAndUpdateCredentials } from 'src/pages/home/modules/reactHealthCredentials/forSystemAdminViewAndUpdateCredentials.jsx';
import { SystemAdminAddCredentials } from 'src/pages/home/modules/reactHealthCredentials/forSystemAdminAddCredentials.jsx';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { Edit } from '@mui/icons-material';
import { capitalizeFirstLetter, deviceManufacturer, formatDeviceManufacturer } from 'src/utils';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

export const SystemAdminCredentials = () => {
  // State variable for loading state
  const [loading, setLoading] = useState(false);

  // State variable for credentials data, initialized as an empty array
  const [credentialsData, setCredentialsData] = useState([]);

  // State variable for view credentials, initialized as an empty string
  const [viewCredentials, setViewCredentials] = useState('');

  // State variable for controlling the visibility of the view dialog, initialized as false
  const [openViewDialog, setOpenViewDialog] = useState(false);

  const [selectedManufacturer, setSelectedManufacturer] = useState(``);

  // State variable for controlling the visibility of the add dialog, initialized as false
  const [openAddDialog, setOpenAddDialog] = useState(false);

  // Function to open the view credentials dialog
  const viewCredentialsDialog = (viewDialogAndID = null) => {
    // Set the view credentials value
    setViewCredentials(viewDialogAndID);
    // Open the view dialog
    setOpenViewDialog(true);
  };

  // Function to open the add credentials dialog
  const addCredentialsDialog = () => {
    // Open the add dialog
    setOpenAddDialog(true);
  };

  const tableRows = () => {
    return credentialsData?.map((mappingData, index) => {
      // Capitalize the first letter of the practiceName obtained from mappingData, _practiceId, and assign the result to practiceNameCapitalized.
      let practiceNameCapitalized = capitalizeFirstLetter(mappingData?._practiceId?.practiceName);

      let manufacturerNameFormatted = formatDeviceManufacturer(mappingData?.deviceManufacturer);

      return (
        <tr draggable="true" key={index}>
          <td className="text-capitalize">{`${mappingData?.friendlyName}`}</td>
          <td className="text-capitalize">{practiceNameCapitalized}</td>
          <td className="text-capitalize">{manufacturerNameFormatted}</td>
          <td className={css.customTd}>
            <IconButton title="Click to Edit Phrase" sx={{ color: `#1699c5` }} onClick={() => viewCredentialsDialog(mappingData)} aria-label="edit">
              <Edit />
            </IconButton>
          </td>
        </tr>
      );
    });
  };

  const handleManufacturerChange = (event) => {
    const newSelectedManufacturer = event.target.value;
    setSelectedManufacturer(newSelectedManufacturer);
  };

  // Function to get all credentials
  const getAllCredentials = () => {
    // Set loading to true
    setLoading(true);

    // Define query parameters
    let queryParamsValue = `?deviceManufacturer=${selectedManufacturer}`;

    // Make GET request to retrieve credentials
    getRequest(EXTERNAL_DEVICE_CREDENTIALS_LIST + queryParamsValue)
      .then((response) => {
        // Set loading to false
        setLoading(false);

        // Check if response status is 200
        if (response.status === 200) {
          // Set credentials data
          setCredentialsData(response?.data?.externalDevices);
        }
      })
      .catch((error) => {
        // Set loading to false in case of error
        setLoading(false);
      });
  };

  // Call getAllCredentials function on component mount
  useEffect(() => {
    getAllCredentials();
  }, [selectedManufacturer]);

  return (
    <div className="p-5">
      <div className="col-auto">
        {/* Render SystemAdminViewAndUpdateCredentials component */}
        <SystemAdminViewAndUpdateCredentials
          viewCredentials={viewCredentials}
          openViewDialog={openViewDialog}
          setOpenViewDialog={setOpenViewDialog}
        />

        {/* Render SystemAdminAddCredentials component */}
        <SystemAdminAddCredentials openAddDialog={openAddDialog} setOpenAddDialog={setOpenAddDialog} getAllCredentials={getAllCredentials} />
      </div>
      <div id={css.practiceContainer} style={{ position: 'relative' }}>
        <div className="table-responsive" style={{ borderRadius: '10px 10px 10px 10px' }}>
          <table className="table table-striped">
            <thead style={{ borderBottom: '1px solid grey' }}>
              {/* First Row for Header and Filter */}
              <tr style={{ backgroundColor: 'white' }}>
                <th colSpan="4">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h6 className="text-dark pt-3">List Of Provider Credentials</h6>

                    <div
                      style={{
                        display: 'flex',
                        gap: '30px',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ width: '300px' }}>
                        <MuiSelect
                          controlProps={{
                            size: 'small',
                            id: 'deviceManufacturer',
                            fullWidth: true,
                          }}
                          label="Filter By Manufacturer"
                          isSimple={false}
                          options={[...deviceManufacturer]}
                          optionKey="label"
                          optionValue="value"
                          selectProps={{
                            label: 'Filter By Manufacturer',
                            id: 'deviceManufacturer',
                            name: 'selectedManufacturer',
                            onChange: handleManufacturerChange,
                            value: selectedManufacturer,
                          }}
                        />
                      </div>

                      <button
                        className={css.customFilterButton}
                        type="button"
                        data-toggle="collapse"
                        data-target="#advanceFilterCard"
                        onClick={() => addCredentialsDialog()}
                      >
                        <i className="fa fa-plus"></i> Add
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>Friendly Name</th>
                <th scope="col">Practice Name</th>
                <th scope="col">Manufacturer Name</th>
                <th scope="col" className={css.customCol4}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody id={css.body}>{tableRows()}</tbody>
          </table>
        </div>
      </div>
      {loading && <PulseSpinner />}
    </div>
  );
};
